import React from 'react';
import './App.css';
import {Router} from "react-router-dom";
import {createBrowserHistory} from 'history';
import {AuthProvider} from "./contexts/JWTAuthContext";
import routes, {renderRoutes} from "./routes";
const history = createBrowserHistory();

function App() {
  return (
    <div className="App bg-gray-50 dark:bg-gray-800">
        <Router history={history}>
            <AuthProvider>
                {renderRoutes(routes)}
            </AuthProvider>
        </Router>

    </div>
  );
}

export default App;
