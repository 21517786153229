import React from 'react';
import {Progress} from "flowbite-react";


const SlashScreen = () => {
  return (
  <div>
    <Progress
        progress={45}
        size="sm"
        color="dark"
    />
  </div>
  );
}

export default SlashScreen;
