import React, {useEffect} from 'react';
import NProgress from 'nprogress';
import {Spinner} from "flowbite-react";

const LoadingScreen = () => {

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div>
        <div className="flex flex-col gap-2">
          <div className="text-center">
            <Spinner aria-label="Center-aligned spinner example" />
          </div>
        </div>
    </div>
  );
};

export default LoadingScreen;
